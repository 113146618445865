export type ProgrammeType = {
    id: number;
    title: string;
    description: string;
    key_stage?: number;
    year_id?: number;
    created_at: string;
    published_at: string;
    status: ProgrammeStatusType | null;
    learning_objectives: LOsType[];
    country: string;
};

export enum ProgrammeStatusType {
    ARCHIVED = 'archived',
    DRAFT = 'draft',
    LIVE = 'live',
}

export type LOsType = {
    id: number;
    title: string;
    programme_position: number;
    year_id: number;
    active: boolean;
    slides_counter: number;
};
