export enum ProgrammeCopyKeys {
    CURRENT_LIVE_PROGRAMME = 'currentLiveProgramme',
    NEW_LIVE_PROGRAMME = 'newLiveProgramme',
}

const getProgrammeCopy = () => ({
    [ProgrammeCopyKeys.CURRENT_LIVE_PROGRAMME]:
        'Teachers won’t be able to add pupils to this programme. Any pupils currently on this programme will continue on it.',
    [ProgrammeCopyKeys.NEW_LIVE_PROGRAMME]:
        'Teachers will be able to assign pupils to this programme.',
});

export const createProgrammeCopy = (itemKey: ProgrammeCopyKeys) => {
    return getProgrammeCopy()[itemKey];
};
