import { useState } from 'react';
import { useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button, Loading } from '@thirdspacelearning/library';

import { ProgrammeForm } from '../programme-details';
import ProgrammesTable from './ProgrammesTable';

import { ProgrammeType } from '../../types';

import {
    ErrorActionPanel,
    PlaceholderActionPanel,
    PageLayout,
} from '../../../shared';

import { getProgrammes, CurriculumQueryKeys, QUERY_STATUS } from '@api';
const ProgrammesPage = () => {
    const [showCreateProgramme, setShowCreateProgramme] = useState(false);

    const { showProgrammesOnCurriculumCms } = useFlags();

    const { status, data: programmes } = useQuery<ProgrammeType[]>(
        [`${CurriculumQueryKeys.PROGRAMMES}`],
        () => getProgrammes(),
        {
            enabled: showProgrammesOnCurriculumCms,
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );

    const handleCreateProgramme = () => {
        setShowCreateProgramme(true);
    };

    if (showProgrammesOnCurriculumCms === false) {
        return <PlaceholderActionPanel />;
    }

    return (
        <PageLayout
            title="Programmes"
            buttons={[
                ...(programmes
                    ? [
                          <Button
                              key="create-new-programme"
                              variant="contained"
                              color="primary"
                              onClick={handleCreateProgramme}
                              data-testid="create-programme-button"
                          >
                              Create New Programme
                          </Button>,
                      ]
                    : []),
            ]}
        >
            {status === QUERY_STATUS.loading && (
                <Loading loadingMessage="Loading programmes..." />
            )}

            {status === QUERY_STATUS.error && (
                <ErrorActionPanel
                    error="There was an error loading the programmes"
                    message="Please refresh the page and try again. If the problem persists, please let us know."
                />
            )}

            {status === QUERY_STATUS.success && (
                <>
                    <ProgrammesTable programmes={programmes} />

                    <ProgrammeForm
                        open={showCreateProgramme}
                        onClose={setShowCreateProgramme}
                    />
                </>
            )}
        </PageLayout>
    );
};

export default ProgrammesPage;
