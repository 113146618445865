import axios, { AxiosError } from 'axios';

import { environment } from '../environments/environment';
import { ProgrammeType } from '../features/programmes';

export const getProgrammes = async () => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/programmes`, {
            withCredentials: true,
            params: {
                fields: 'id,title,key_stage,year_id,created_at,updated_at,status',
            },
        })
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Something went wrong loading the programmes.');
        });
};

export const getProgramme = async (programmeId: string | undefined) => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/programmes/${programmeId}`, {
            withCredentials: true,
        })
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Something went wrong loading the programme.');
        });
};

export const createProgramme = async (
    params: Partial<ProgrammeType>
): Promise<ProgrammeType> => {
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/programmes/`,
            { v1_programme: params },
            {
                withCredentials: true,
            }
        )
        .then((response) => response.data)
        .catch((error: AxiosError) => {
            throw new Error('Error creating programme.');
        });
};

export const updateProgramme = async (
    programmeId: number | undefined,
    params: Partial<ProgrammeType>
) => {
    return axios
        .put(
            `${environment.curriculumCmsApiUrl}/programmes/${programmeId}`,
            { v1_programme: params },
            {
                withCredentials: true,
            }
        )
        .catch((error: AxiosError) => {
            if (error.response?.status === 422) {
                throw new Error('This programme doesn’t have 20 objectives');
            }

            throw new Error('Please refresh the page or try again');
        });
};

export const deleteProgramme = async (programmeId: number | undefined) => {
    return axios
        .delete(
            `${environment.curriculumCmsApiUrl}/programmes/${programmeId}`,
            {
                withCredentials: true,
            }
        )
        .catch(() => {
            throw new Error('Please refresh the page or try again');
        });
};

export const getLearningObjective = async (loId: number | undefined) => {
    return axios
        .get(`${environment.curriculumCmsApiUrl}/learning_objectives/${loId}`, {
            withCredentials: true,
        })
        .then((response) => response.data)
        .catch(() => {
            throw new Error(
                'Something went wrong loading the learning objective.'
            );
        });
};

export const addLearningObjectives = async (
    programmeId: number,
    loIds: number[] | undefined
) => {
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/programmes/${programmeId}/add_learning_objectives`,
            { learning_objective_ids: loIds },
            {
                withCredentials: true,
            }
        )
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Error adding learning objectives.');
        });
};

export const deleteLearningObjectives = async (
    programmeId: number,
    loIds: number[] | undefined
) => {
    return axios
        .delete(
            `${environment.curriculumCmsApiUrl}/programmes/${programmeId}/remove_learning_objectives`,
            {
                withCredentials: true,
                data: { learning_objective_ids: loIds },
            }
        )
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Error removing learning objective.');
        });
};

export const reorderLearningObjectives = async (
    programmeId: number,
    los: { id: number; position: number }[]
) => {
    return axios
        .post(
            `${environment.curriculumCmsApiUrl}/programmes/${programmeId}/reorder_learning_objectives`,
            { learning_objectives: los },
            {
                withCredentials: true,
            }
        )
        .then((response) => response.data)
        .catch(() => {
            throw new Error('Error reordering learning objectives.');
        });
};
