import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LoadingButton,
    SelectFinalField,
    TextFinalField,
} from '@thirdspacelearning/library';
import { required } from '@tsl-frontend/tsl-utilities';

import { useUpdateProgramme } from '../../hooks';
import { ProgrammeType } from '../../types';
import { countryOptions, keyStageOptions } from '../../utils';

import { createProgramme } from '@api';

const sx = {
    dialog: {
        '& .MuiDialog-paper': {
            p: 3,
            gap: 2,
        },
    },
};

type Props = {
    open: boolean;
    onClose: (show: boolean) => void;
    programme?: ProgrammeType;
};

/**
 * Programme Form used to create or edit a programme.
 */
const ProgrammeForm: FC<Props> = ({ open, onClose, programme }) => {
    const navigate = useNavigate();

    const [isPristine, setPristine] = useState(true);

    const { mutate: createNewProgramme, isLoading: isCreating } = useMutation<
        ProgrammeType,
        Error,
        Partial<ProgrammeType>
    >({
        mutationFn: (params: Partial<ProgrammeType>) => createProgramme(params),
        onSuccess: (data) => {
            navigate(`/programmes/${data.id}`);
        },
        onError: (error: Error) => {
            enqueueSnackbar(error.message, {
                title: 'There was an error creating the programme',
                key: 'create-programme-error',
                testId: 'create-programme-error',
                variant: 'curriculumSnackbar',
                severity: 'error',
            });
        },
    });

    const { updateProgramme, isUpdating } = useUpdateProgramme(
        programme?.id,
        'Programme edited successfully!',
        undefined,
        () => onClose(false)
    );

    const onSubmit = (values: any) => {
        const params = {
            title: values.title,
            description: values.description,
            country: values.country.toUpperCase(),
            key_stage: Number(values.keyStage),
            year_id: Number(values.yearId),
        };

        if (programme) {
            updateProgramme(params);
        } else {
            createNewProgramme(params);
        }
    };

    const handleClose = (event: any, reason: string) => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose(false);
    };

    const isLoading = isCreating || isUpdating;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={sx.dialog}
            data-testid="programme-form"
        >
            <DialogTitle align="center" fontWeight="bold" sx={{ p: 0 }}>
                {programme ? 'Edit programme' : 'Create new programme'}
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        country: 'uk',
                        title: programme?.title || '',
                        keyStage: programme?.key_stage?.toString() || '',
                        yearId: programme?.year_id?.toString() || '',
                        description: programme?.description || '',
                    }}
                    render={({ handleSubmit, values, form }) => (
                        <form
                            onSubmit={handleSubmit}
                            id="createNewProgramme"
                            noValidate
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="450px"
                            >
                                <Field
                                    name="country"
                                    component={SelectFinalField}
                                    label="Region (required)"
                                    options={countryOptions}
                                    variant="outlined"
                                    validate={required}
                                    required
                                    disabled
                                    fullWidth
                                />
                                <Field
                                    name="title"
                                    component={TextFinalField}
                                    label="Programme name (required)"
                                    placeholder="Input text"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 45 }}
                                    validate={required}
                                    required
                                    fullWidth
                                    sx={{ mb: 1 }}
                                    data-testid="title-input"
                                />
                                <Field
                                    name="keyStage"
                                    component={SelectFinalField}
                                    label="Key Stage *"
                                    options={keyStageOptions}
                                    variant="outlined"
                                    validate={required}
                                    required
                                    fullWidth
                                    onChangeCallback={() => {
                                        form.change('yearId', undefined);
                                        form.resetFieldState('yearId');
                                    }}
                                />
                                <Field
                                    name="yearId"
                                    component={SelectFinalField}
                                    label="Core year group *"
                                    options={
                                        values.keyStage
                                            ? keyStageOptions.find(
                                                  (option) =>
                                                      option.id ===
                                                      values.keyStage
                                              )?.yearGroups
                                            : []
                                    }
                                    variant="outlined"
                                    disabled={!values.keyStage}
                                    validate={required}
                                    required
                                    fullWidth
                                />
                                <Field
                                    name="description"
                                    component={TextFinalField}
                                    label="Programme description (required)"
                                    placeholder="Input text"
                                    InputLabelProps={{ shrink: true }}
                                    validate={required}
                                    inputProps={{ maxLength: 180 }}
                                    maxRows={3}
                                    multiline
                                    required
                                    fullWidth
                                    sx={{ mt: 2, mb: 0 }}
                                    data-testid="description-input"
                                />
                            </Box>
                            <FormSpy
                                subscription={{
                                    pristine: true,
                                }}
                                onChange={({ pristine }) => {
                                    if (!pristine) {
                                        setPristine(false);
                                    }
                                }}
                            />
                        </form>
                    )}
                />
            </DialogContent>
            <DialogActions sx={{ display: 'flex' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onClose(false)}
                    fullWidth
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="createNewProgramme"
                    fullWidth
                    loading={isLoading}
                    disabled={isPristine}
                    data-testid="programme-submit-button"
                >
                    {programme ? 'Edit programme' : 'Create programme'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ProgrammeForm;
