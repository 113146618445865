import { FC, useMemo, useState } from 'react';
import { useMutation, useQueries } from 'react-query';
import { enqueueSnackbar } from 'notistack';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    GridPaginationModel,
    GridRowSelectionModel,
    GridSortModel,
    Loading,
    LoadingButton,
} from '@thirdspacelearning/library';

import ProgrammeLoFilters from './ProgrammeLOFilters';
import ProgrammeLOTable from './ProgrammeLOTable';

import { ErrorActionPanel } from '../../../shared';
import { LearningObjective, SearchParams, Year } from '../../../../types';
import { defaultSearchParams, defaultTableParams } from '../../utils';
import { LOsType } from '../../types';

import {
    queryClient,
    CurriculumQueryKeys,
    getLearningObjectives,
    getYears,
    addLearningObjectives,
} from '@api';

const sx = {
    dialog: {
        '& .MuiDialog-paper': {
            width: '90vw',
            height: '90vh',
            p: 3,
            gap: 2,
        },
    },
    dialogTitle: { p: 0 },
    dialogActions: { display: 'flex', p: 0 },
};

type Props = {
    open: boolean;
    onClose: (show: boolean) => void;
    programmeId: number;
    programmeLOs: LOsType[];
};

const ProgrammeLOSelection: FC<Props> = ({
    open,
    onClose,
    programmeId,
    programmeLOs,
}) => {
    const [searchParams, setSearchParams] =
        useState<SearchParams>(defaultSearchParams);

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
        {
            page: defaultTableParams.page,
            pageSize: defaultTableParams.per_page,
        }
    );

    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>([]);

    const [losQuery, yearsQuery] = useQueries([
        {
            queryKey: [
                `${CurriculumQueryKeys.LOS}`,
                paginationModel,
                searchParams,
            ],
            queryFn: () =>
                getLearningObjectives({
                    ...searchParams,
                    page: paginationModel.page + 1,
                    per_page: paginationModel.pageSize,
                }),
            retry: 1,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            staleTime: Infinity,
        },
        {
            queryKey: [CurriculumQueryKeys.YEARS],
            queryFn: () => getYears(),
            retry: 1,
            refetchOnWindowFocus: false,
        },
    ]);

    const { mutate: addLOs, isLoading: isAddingLOs } = useMutation({
        mutationFn: () =>
            addLearningObjectives(programmeId, rowSelectionModel as number[]),
        onSuccess: () => {
            enqueueSnackbar('Successfully added learning objectives.', {
                key: 'add-los-success',
                testId: 'add-los-success',
                variant: 'curriculumSnackbar',
                severity: 'success',
            });

            onClose(false);

            queryClient.invalidateQueries(
                `${CurriculumQueryKeys.PROGRAMME}-${programmeId}`
            );
        },
        onError: (error: Error) => {
            enqueueSnackbar('Please refresh or try again.', {
                title: error.message,
                key: 'add-los-error',
                testId: 'add-los-error',
                variant: 'curriculumSnackbar',
                severity: 'error',
            });
        },
    });

    const handleSort = (sortModel: GridSortModel) => {
        let sort = '';

        if (sortModel.length > 0) {
            const sorting = sortModel[0].sort === 'asc' ? '' : '-';
            sort = `${sorting}${sortModel[0].field}`;
        }

        setSearchParams({
            ...searchParams,
            sort,
        });
    };

    const onSubmit = () => {
        addLOs();
    };

    const isLoading = [losQuery, yearsQuery].some((query) => query.isLoading);
    const isFetching = [losQuery, yearsQuery].some((query) => query.isFetching);
    const isError = [losQuery, yearsQuery].some((query) => query.isError);

    const los = losQuery.data;
    const years = yearsQuery.data;

    const programmeLoIds = useMemo(() => {
        return programmeLOs.map((lo) => lo.id);
    }, [programmeLOs]);

    return (
        <Dialog open={open} maxWidth={'lg'} sx={sx.dialog}>
            <DialogTitle
                align="center"
                fontWeight="bold"
                sx={sx.dialogTitle}
                data-testid="los-dialog-title"
            >
                Select objectives to add to a programme
            </DialogTitle>

            {isLoading && !los && (
                <Loading loadingMessage="Loading learning objectives..." />
            )}

            {isError && (
                <ErrorActionPanel
                    error="There was an error loading the learning objectives"
                    message="Please refresh the page and try again. If the problem persists, please let us know."
                />
            )}

            {los && years && (
                <>
                    <ProgrammeLoFilters
                        years={years as Year[]}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                    />
                    <DialogContent sx={{ p: 0 }}>
                        <ProgrammeLOTable
                            learningObjectives={
                                los.learningObjectives as LearningObjective[]
                            }
                            total={los.totalCount as number}
                            loading={isFetching}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            onSortModelChange={handleSort}
                            rowSelectionModel={rowSelectionModel}
                            setRowSelectionModel={setRowSelectionModel}
                            programmeLoIds={programmeLoIds}
                        />
                    </DialogContent>

                    <DialogActions sx={sx.dialogActions}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => onClose(false)}
                            disabled={isFetching}
                            fullWidth
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={isAddingLOs}
                            onClick={onSubmit}
                            disabled={
                                isFetching || rowSelectionModel.length === 0
                            }
                            fullWidth
                            data-testid="add-los-button"
                        >
                            Add Objectives
                        </LoadingButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default ProgrammeLOSelection;
