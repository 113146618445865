/**
 * Can be used to pluralise a given text based on an items length/ count.
 *
 * @param { string } label singular text
 * @param { number } length length of items
 */
export const pluralisedString = (label: string, length: number = 1) =>
    `${label}${length > 1 ? 's' : ''}`;

/**
 * Can be used to capitalise a given text
 *
 * @param {string} s string to capitalise
 */
export const capitalise = (s: string | null): string => {
    if (typeof s !== 'string' || !s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
