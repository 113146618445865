import { FC, useCallback } from 'react';
import {
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import {
    Box,
    Chip,
    DataGrid,
    GridColDef,
    Tooltip,
} from '@thirdspacelearning/library';

import DraggableGridRow from './DraggableGridRow';
import DragHandleIcon from './DragHandleIcon';

import { yearGroupSortComparator } from '../../../utils';
import { LOsType } from '../../../types';

const sx = {
    grid: {
        '& .MuiDataGrid-row:nth-of-type(odd)': {
            bgcolor: 'common.white', // Reset row colour
        },
    },
};

type Props = {
    sortedLOs: LOsType[];
    setSortedLOs: (sortedLOs: LOsType[]) => void;
};

const ProgrammeLoReorderTable: FC<Props> = ({ sortedLOs, setSortedLOs }) => {
    const sensors = useSensors(useSensor(PointerSensor));

    const columns: GridColDef<LOsType>[] = [
        {
            field: 'icon',
            headerName: '',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            minWidth: 40,
            maxWidth: 40,
            renderCell: () => <DragHandleIcon />,
        },
        {
            field: 'title',
            type: 'string',
            headerName: 'Learning objective',
            renderCell: ({ row }) => (
                <Tooltip
                    title={row.title}
                    enterDelay={500}
                    disableInteractive
                    arrow
                >
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {row.title}
                    </Box>
                </Tooltip>
            ),
            sortable: false,
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'yearGroup',
            type: 'string',
            headerName: 'Year group',
            headerAlign: 'center',
            align: 'center',
            sortingOrder: ['asc', 'desc'],
            sortComparator: yearGroupSortComparator,
            renderCell: ({ row }) =>
                row.year_id ? (
                    <Chip
                        label={`Year ${row.year_id}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: false,
            minWidth: 110,
        },
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
        },
    ];

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;
            if (over) {
                const oldIndex = sortedLOs.findIndex(
                    (los) => los.id === active.id
                );
                const newIndex = sortedLOs.findIndex(
                    (los) => los.id === over.id
                );
                setSortedLOs(arrayMove(sortedLOs, oldIndex, newIndex));
            }
        },
        [sortedLOs]
    );

    const loIds = sortedLOs.map((lo) => lo.id);

    return (
        sortedLOs && (
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext items={loIds}>
                    <DataGrid
                        columns={columns}
                        rows={sortedLOs}
                        disableColumnMenu
                        disableColumnSelector
                        disableColumnFilter
                        disableDensitySelector
                        disableRowSelectionOnClick
                        hideFooter
                        autoHeight
                        slots={{ row: DraggableGridRow }}
                        sx={sx.grid}
                    />
                </SortableContext>
            </DndContext>
        )
    );
};

export default ProgrammeLoReorderTable;
