export const countryOptions = [
    { id: 'uk', name: 'UK' },
    { id: 'us', name: 'US' },
];

export const keyStageOptions = [
    {
        id: '1',
        name: 'Key stage 1',
        yearGroups: [
            {
                id: '1',
                name: 'Year 1',
            },
            {
                id: '2',
                name: 'Year 2',
            },
        ],
    },
    {
        id: '2',
        name: 'Key stage 2',
        yearGroups: [
            {
                id: '3',
                name: 'Year 3',
            },
            {
                id: '4',
                name: 'Year 4',
            },
            {
                id: '5',
                name: 'Year 5',
            },
            {
                id: '6',
                name: 'Year 6',
            },
        ],
    },
    {
        id: '3',
        name: 'Key stage 3',
        yearGroups: [
            {
                id: '7',
                name: 'Year 7',
            },
            {
                id: '8',
                name: 'Year 8',
            },
        ],
    },
    {
        id: '4',
        name: 'Key stage 4',
        yearGroups: [
            {
                id: '10',
                name: 'Year 10',
            },
            {
                id: '11',
                name: 'Year 11',
            },
        ],
    },
];
