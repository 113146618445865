import { FC, useState } from 'react';

import { dayjs } from '@tsl-frontend/tsl-utilities';
import {
    Box,
    Chip,
    DataGrid,
    GridColDef,
    GridSortModel,
    Tooltip,
    TSLLink,
} from '@thirdspacelearning/library';

import { ProgrammeType } from '../../types';
import { useDataGridToolbar } from '../../../shared';
import { statusMap, yearGroupSortComparator } from '../../utils';

import { capitalise } from '@utils';

const sx = {
    root: { display: 'flex', flexDirection: 'column', gap: 2 },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

type Props = {
    programmes: ProgrammeType[];
};

const ProgrammesTable: FC<Props> = ({ programmes }) => {
    const { filterPanelEl, slots, slotProps } = useDataGridToolbar();

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'yearGroup',
            sort: 'asc',
        },
    ]);

    const columns: GridColDef<ProgrammeType>[] = [
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            headerAlign: 'left',
            align: 'left',
            sortable: true,
            maxWidth: 70,
        },
        {
            field: 'programmeName',
            type: 'string',
            headerName: 'Programme name',
            valueGetter: ({ row }) => row.title,
            renderCell: ({ row }) => (
                <Tooltip
                    title={row.title}
                    enterDelay={500}
                    disableInteractive
                    arrow
                >
                    <Box sx={sx.title}>
                        <TSLLink
                            to={`${row.id}`}
                            variant="body2"
                            data-testid="programme-link"
                        >
                            {row.title}
                        </TSLLink>
                    </Box>
                </Tooltip>
            ),
            sortable: true,
            minWidth: 250,
            flex: 1.5,
        },
        {
            field: 'keyStage',
            type: 'string',
            headerName: 'Key Stage',
            valueGetter: ({ row }) => row.key_stage,
            renderCell: ({ row }) =>
                row.key_stage ? (
                    <Chip
                        label={`Key Stage ${row.key_stage}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'yearGroup',
            type: 'string',
            headerName: 'Year group',
            sortingOrder: ['asc', 'desc'],
            sortComparator: yearGroupSortComparator,
            valueGetter: ({ row }) => row.year_id,
            renderCell: ({ row }) =>
                row.year_id ? (
                    <Chip
                        label={`Year ${row.year_id}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 130,
            flex: 0.5,
        },
        {
            field: 'created_at',
            type: 'date',
            headerName: 'Created date',
            valueFormatter: (params) =>
                dayjs(params.value).format('DD/MM/YY HH:mm'),
            sortingOrder: ['asc', 'desc'],
            sortable: true,
            minWidth: 170,
            flex: 0.5,
        },
        {
            field: 'updated_at',
            type: 'date',
            headerName: 'Last modified',
            valueFormatter: (params) =>
                dayjs(params.value).format('DD/MM/YY HH:mm'),
            sortingOrder: ['asc', 'desc'],
            sortable: true,
            minWidth: 170,
            flex: 0.5,
        },
        {
            field: 'status',
            type: 'string',
            headerName: 'Status',
            valueGetter: ({ row }) => row.status,
            renderCell: ({ row }) =>
                row.status ? (
                    <Chip
                        label={capitalise(row.status)}
                        color={statusMap[row.status]}
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 100,
            flex: 0.5,
        },
    ];

    return (
        <Box sx={sx.root}>
            <Box id="filter-panel" ref={filterPanelEl} />
            <DataGrid
                columns={columns}
                rows={programmes}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                slots={slots}
                slotProps={slotProps}
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                hideFooter
                autoHeight
            />
        </Box>
    );
};

export default ProgrammesTable;
