import { FC, useMemo } from 'react';

import {
    Box,
    ConfirmationDialog,
    SectionMessage,
} from '@thirdspacelearning/library';

import { createProgrammeCopy, ProgrammeCopyKeys } from '../../copy';
import { useUpdateProgramme } from '../../hooks';
import { ProgrammeStatusType } from '../../types';

const sx = {
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: '380px',
        },
    },
};

type Props = {
    selectedStatus: ProgrammeStatusType | null;
    currentStatus: ProgrammeStatusType | null;
    setSelectedStatus: (selectedStatus: ProgrammeStatusType | null) => void;
    programmeId: number;
};

const ProgrammeStatus: FC<Props> = ({
    programmeId,
    currentStatus,
    selectedStatus,
    setSelectedStatus,
}) => {
    const { updateProgramme, isUpdating } = useUpdateProgramme(
        programmeId,
        'Status updated successfully.',
        'There was an error changing the status',
        () => setSelectedStatus(null),
        () => setSelectedStatus(null)
    );

    const onConfirmDialog = () => {
        updateProgramme({
            status: selectedStatus,
        });
    };

    const onCloseDialog = () => {
        setSelectedStatus(null);
    };

    const warningMessage = useMemo(() => {
        if (selectedStatus === ProgrammeStatusType.LIVE) {
            return createProgrammeCopy(ProgrammeCopyKeys.NEW_LIVE_PROGRAMME);
        }

        if (currentStatus === ProgrammeStatusType.LIVE) {
            return createProgrammeCopy(
                ProgrammeCopyKeys.CURRENT_LIVE_PROGRAMME
            );
        }

        return null;
    }, [currentStatus, selectedStatus]);

    return (
        <ConfirmationDialog
            open={!!selectedStatus}
            title={`Set programme to ${selectedStatus}?`}
            confirmLabel={`Set to ${selectedStatus}`}
            // setting as loading when no selected status to
            // prevent showing the label as "Set to null"
            loadingActionButton={isUpdating || !selectedStatus}
            message={
                <>
                    {warningMessage && (
                        <Box textAlign="left">
                            <SectionMessage
                                type="warning"
                                title={warningMessage}
                            />
                        </Box>
                    )}
                </>
            }
            onConfirm={onConfirmDialog}
            onClose={onCloseDialog}
            sx={sx.dialog}
            testId="status-dialog"
        />
    );
};

export default ProgrammeStatus;
