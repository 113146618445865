import { useMutation } from 'react-query';
import { ProgrammeType } from '../types';
import { updateProgramme as putProgramme } from '../../../api/programmes';
import { CurriculumQueryKeys } from '../../../api/services/curriculum-query-keys';
import { enqueueSnackbar } from 'notistack';
import { queryClient } from '../../../api/services/query-client';

export const useUpdateProgramme = (
    programmeId: number | undefined,
    successMessage: string,
    errorMessage?: string,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void
) => {
    const { mutate: updateProgramme, isLoading: isUpdating } = useMutation({
        mutationFn: (data: Partial<ProgrammeType>) =>
            putProgramme(programmeId, data),
        onSuccess: () => {
            enqueueSnackbar(successMessage, {
                key: 'edit-programme-success',
                testId: 'edit-programme-success',
                variant: 'curriculumSnackbar',
                severity: 'success',
            });

            queryClient.invalidateQueries(
                `${CurriculumQueryKeys.PROGRAMME}-${programmeId}`
            );

            if (onSuccessCallback) onSuccessCallback();
        },
        onError: (error: Error) => {
            enqueueSnackbar(error?.message, {
                title:
                    errorMessage || 'There was an error editing the programme',
                key: 'edit-programme-error',
                testId: 'edit-programme-error',
                variant: 'curriculumSnackbar',
                severity: 'error',
            });

            if (onErrorCallback) onErrorCallback();
        },
    });

    return { updateProgramme, isUpdating };
};
