import axios from 'axios';
import { User } from '../types';

import { environment } from '../environments/environment';

export interface RefreshUserResponse {
    user: User;
    token: string;
    success: boolean;
    redirect_to: string;
}

export const refreshUser = async (): Promise<RefreshUserResponse> => {
    return axios
        .get(`${environment.usersApiUrl}/refresh`, {
            withCredentials: true,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            //server returns a 401 if the refresh was unsuccessful
            //return nothing here and redirect to login page.
        });
};
