import { useNavigate } from 'react-router-dom';

import { ActionPanel, Box } from '@thirdspacelearning/library';

import placeholderSvg from '../../../assets/placeholder.svg';

const sx = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
};

const PlaceholderActionPanel = () => {
    const navigate = useNavigate();

    const onNavigation = () => {
        navigate('/');
    };

    return (
        <Box sx={sx.actionPanel}>
            <ActionPanel
                buttonAction={onNavigation}
                buttonLabel="Return To Home"
                decoration={<img src={placeholderSvg} alt="Come back soon" />}
                title="We are working on improving the Curriculum CMS"
                textContent="Come back soon to see the new page!"
            />
        </Box>
    );
};

export default PlaceholderActionPanel;
