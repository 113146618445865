import { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Box, GridRowProps, GridRow } from '@thirdspacelearning/library';

const DraggableGridRow: FC<GridRowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props.rowId });

    return (
        <Box
            ref={setNodeRef}
            sx={{
                transform: CSS.Transform.toString(transform),
                transition,
                zIndex: isDragging ? 1000 : 'auto',
            }}
            {...attributes}
            {...listeners}
            data-testid="row-container"
        >
            <GridRow {...props} />
        </Box>
    );
};

export default DraggableGridRow;
