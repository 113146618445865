import { FC, useMemo, useState } from 'react';

import {
    Box,
    Button,
    GridPaginationModel,
    SearchField,
    Select,
    SelectChangeEvent,
} from '@thirdspacelearning/library';

import {
    defaultFilters,
    defaultSearchParams,
    loActiveOptions,
    loTypeOptions,
} from '../../utils';

import { LearningObjectiveType, SearchParams, Year } from '../../../../types';

const sx = {
    filters: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: 2,
    },
    select: { formControl: { flexGrow: 1 } },
};

type Props = {
    years: Year[];
    searchParams: SearchParams;
    setSearchParams: (params: SearchParams) => void;
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
};

const ProgrammeLoFilters: FC<Props> = ({
    years,
    searchParams,
    setSearchParams,
    paginationModel,
    setPaginationModel,
}) => {
    const [filters, setFilters] = useState(defaultFilters);

    const sortedYears = useMemo(() => {
        return years
            .sort((a, b) => a.id - b.id)
            .map((year) => ({ ...year, id: year.id.toString() }));
    }, [years]);

    const yearsMap: Record<
        LearningObjectiveType,
        { id: string; name: string }[]
    > = {
        [LearningObjectiveType.SATS]: sortedYears.filter((year) => {
            return year.name === 'Year 6';
        }),
        [LearningObjectiveType.GCSE]: sortedYears.filter((year) => {
            return ['Year 10', 'Year 11'].includes(year.name);
        }),
        [LearningObjectiveType.STANDARD]: sortedYears,
    };

    const resetPage = () => {
        setPaginationModel({
            ...paginationModel,
            page: 0,
        });
    };

    const handleSearch = (filters: any) => {
        setSearchParams({
            ...searchParams,
            title: filters.title || '',
        });

        resetPage();
    };

    const onSelectChange = (newParams: any) => {
        setSearchParams({
            ...searchParams,
            ...newParams,
        });

        resetPage();

        setFilters({
            ...filters,
            ...newParams,
        });
    };

    const onTypeChange = (event: SelectChangeEvent<unknown>) => {
        onSelectChange({
            year_id: '', // reset year ids
            [event.target.name]: event.target.value as LearningObjectiveType,
        });
    };

    const onYearIdChange = (event: SelectChangeEvent<unknown>) => {
        onSelectChange({
            [event.target.name]: event.target.value,
        });
    };

    const onActiveChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value === 'active';

        onSelectChange({
            [event.target.name]: value,
        });

        setFilters({
            ...filters,
            [event.target.name]: event.target.value,
        });
    };

    const onClear = () => {
        setSearchParams(defaultSearchParams);
        resetPage();
        setFilters(defaultFilters);
    };

    return (
        <Box>
            <Box sx={sx.filters}>
                <Select
                    options={loTypeOptions}
                    value={filters.type}
                    name={'type'}
                    variant="outlined"
                    size="small"
                    sx={sx.select}
                    label="Type"
                    handleChange={onTypeChange}
                    data-testid="los-type-filter"
                />
                <Select
                    options={yearsMap[filters.type]}
                    value={filters.year_id}
                    name={'year_id'}
                    variant="outlined"
                    size="small"
                    sx={sx.select}
                    label="Year Group"
                    handleChange={onYearIdChange}
                    data-testid="los-year-id-filter"
                />
                <Select
                    options={loActiveOptions}
                    value={filters.active}
                    name={'active'}
                    variant="outlined"
                    size="small"
                    sx={sx.select}
                    label="Active"
                    handleChange={onActiveChange}
                    data-testid="los-active-filter"
                />
                <Button
                    variant="text"
                    color="primary"
                    onClick={onClear}
                    data-testid="clear-filters"
                >
                    Clear
                </Button>
            </Box>
            <SearchField searchFilters={handleSearch} searchParam="title" />
        </Box>
    );
};

export default ProgrammeLoFilters;
