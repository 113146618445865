import { FC } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import {
    ConfirmationDialog,
    SectionMessage,
} from '@thirdspacelearning/library';

import { deleteProgramme } from '@api';

type Props = {
    open: boolean;
    programmeId: number;
    programmeTitle: string;
    setShowDeleteProgramme: (show: boolean) => void;
};

const ProgrammeDeletion: FC<Props> = ({
    open,
    programmeId,
    programmeTitle,
    setShowDeleteProgramme,
}) => {
    const navigate = useNavigate();

    const { mutate: deleteProgrammeFn, isLoading: isDeletingProgramme } =
        useMutation({
            mutationFn: () => deleteProgramme(Number(programmeId)),
            onSuccess: () => {
                enqueueSnackbar('Successfully deleted the programme.', {
                    key: 'delete-programme-success',
                    testId: 'delete-programme-success',
                    variant: 'curriculumSnackbar',
                    severity: 'success',
                });

                navigate('/programmes');
            },
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    title: 'There was an error deleting the programme',
                    key: 'delete-programme-error',
                    testId: 'delete-programme-error',
                    variant: 'curriculumSnackbar',
                    severity: 'error',
                });
            },
        });

    const onConfirmDialog = () => {
        deleteProgrammeFn();
    };

    const onCloseDialog = () => {
        setShowDeleteProgramme(false);
    };

    return (
        <ConfirmationDialog
            open={open}
            title={`Delete ${programmeTitle}?`}
            confirmLabel="Delete"
            confirmButtonColor="error"
            loadingActionButton={isDeletingProgramme}
            message={
                <SectionMessage
                    type="error"
                    title="This programme will be removed from the CMS."
                    body="LOs will not be deleted."
                />
            }
            onConfirm={onConfirmDialog}
            onClose={onCloseDialog}
            testId="status-dialog"
        />
    );
};

export default ProgrammeDeletion;
