import { LearningObjectiveType, SearchParams } from '../../../types';

export const defaultSearchParams: SearchParams = {
    type: LearningObjectiveType.STANDARD,
    title: '',
    sort: '',
};

export const defaultFilters = {
    year_id: '',
    active: '',
    type: LearningObjectiveType.STANDARD,
};

export const defaultTableParams = { per_page: 25, page: 0, totalCount: 0 };

export const loTypeOptions = [
    {
        id: LearningObjectiveType.STANDARD,
        name: 'Standard',
    },
    { id: LearningObjectiveType.SATS, name: 'SATs' },
    { id: LearningObjectiveType.GCSE, name: 'GCSEs' },
];

export const loActiveOptions = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
];
